<form [formGroup]="formGroup" novalidate>
  <nsc-card>
    <nsc-card-title note="All fields required, unless otherwise indicated">Recipient</nsc-card-title>
    <nsc-card-content>
      <div class="row">
        <div class="col-12 col-md-10">
          <div class="mat-caption">
            According to the Family Educational Rights and Privacy Act (FERPA), in certain instances, schools must obtain the student's permission in order to release information from his or her educational records.  The type of consent form that is required is determined by recipient type.
          </div>
        </div>
        <div class="col-12">
          <nsc-spacer size="sm"></nsc-spacer>
        </div>
        <div class="col-12 col-md-8 col-lg-8">
          <nsc-form-field-select
            idx="recipientType_select"
            controlName="recipientType"
            placeholder="Who are you sending your transcript to?"
            [group]="formGroup"
            [selectObjects]="values.recipientTypes$.value"></nsc-form-field-select>
        </div>
      </div>

      <nsc-recipient-select-college
        *ngIf="formGroup.controls['recipientType'].value === values.college"
        [formGroup]="formGroup"
        [snSchool]="sending"
        (loadingOutput)='onLoadingChange($event)'></nsc-recipient-select-college>

      <div class="col-12"></div>

      <nsc-recipient-select-organization
        *ngIf="formGroup.controls['recipientType'].value === values.organization"
        [formGroup]="formGroup"></nsc-recipient-select-organization>

      <div class="row" *ngIf="formGroup.controls['recipientType'].value === values.business">
        <div class="col-12 col-md-10 col-lg-6">
          <nsc-form-field-input
            idx="business_field"
            controlName="business"
            maxlength="60"
            placeholder="Enter Business or Individual's Name"
            [group]="formGroup"></nsc-form-field-input>
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>

  <div *ngIf="formGroup.controls['electronicDeliveryUnavailableFlag'].value || formGroup.controls['noDeliveryMethodAvailble'].value">
    <nsc-spacer size="md"></nsc-spacer>
    <nsc-card [error]="true">
      <nsc-card-title-error icon="warning" title="{{content.errorTitle}}">
        <div >
          {{content.errorDescription}}
        </div>
      </nsc-card-title-error>
    
    </nsc-card>
  </div>



  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button
      mat-stroked-button
      id="recepientselect_cancel"
      class="nsc-form-actions__button button-big"
      color="primary"
      type="button"
      (click)="cancel()">Cancel Order</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      mat-raised-button
      id="recepientselect_contine"
      class="nsc-form-actions__button  nsc-btn__right button-big"
      color="primary"
      type="button"
      [disabled]="!(formGroup.valid) || loading || isErrorOnlyElectronicDelivery() || formGroup.controls['noDeliveryMethodAvailble'].value"
      (click)="continue()">Continue <mat-icon aria-hidden="true" *ngIf="formGroup.valid && !isErrorOnlyElectronicDelivery() && !formGroup.controls['noDeliveryMethodAvailble'].value">chevron_right</mat-icon></button>
  </div>
</form>
