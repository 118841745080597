<form [formGroup]="formGroup" novalidate>
  <nsc-card>
    <nsc-card-title note="All fields required, unless otherwise indicated">Processing Details</nsc-card-title>
    <nsc-card-content>
      <div class="row">

          <div class="col-12">
            <div class="row">
              <div class="col-12 col-md-8 col-lg-6">
                  <nsc-form-field-select
                  idx="delivery_transcript"
                  controlName="transcriptWhen"
                  placeholder="When do you want your transcript processed?"
                  [group]="formGroup"
                  [selectObjects]="values.transcriptWhen">
                </nsc-form-field-select>
              </div>
            </div>
             <p class="nsc-helpText " *ngIf="content.helpText" [attr.aria-label]="'note'+ content.helpText"><b>NOTE:</b> {{content.helpText}}</p>
           </div>

        <div class="col-12"></div>

        <div class="col-12" *ngIf="show.additionalWhenQuestions">
          <div class="row">
            <div class="col-12 col-sm-6" *ngIf="formGroup.controls['course1'].enabled">
              <nsc-form-field-input
                idx="course1_field"
                controlName="course1"
                maxlength="80"
                placeholder="Course #1"
                [group]="formGroup"></nsc-form-field-input>
            </div>
            <div class="col-12 col-sm-6" *ngIf="formGroup.controls['course2'].enabled">
              <nsc-form-field-input
                idx="course2_field"
                controlName="course2"
                maxlength="80"
                placeholder="Course #2"
                [group]="formGroup"
                [required]="false"></nsc-form-field-input>
            </div>

            <div class="col-12">
              <div class="row">
                <div class="col-12" *ngIf="show.labelForG">
                  <div class="mat-body-2 mat-hint">Will Be Posted:</div>
                </div>
                <div class="col-12" *ngIf="show.labelForD">
                  <div class="mat-body-2 mat-hint">Degree Will Be Awarded On:</div>
                </div>
                <div class="col-12 col-sm-6" *ngIf="formGroup.controls['ungradedTerm'].enabled">
                  <nsc-form-field-select
                    idx="delivery_ungradedTerm"
                    controlName="ungradedTerm"
                    placeholder="Term"
                    [group]="formGroup"
                    [selectObjects]="values.ungradedTerms"></nsc-form-field-select>
                </div>
                <div class="col-12 col-sm-6" *ngIf="formGroup.controls['program'].enabled">
                  <nsc-form-field-select
                    idx="delivery_program"
                    controlName="program"
                    placeholder="Degree Title"
                    [group]="formGroup"
                    [selectObjects]="values.programs"></nsc-form-field-select>
                </div>
                <div class="col-12 col-sm-6" *ngIf="formGroup.controls['term'].enabled && !show.futureProcessingOption">
                  <nsc-form-field-input
                    idx="delivery_term"
                    controlName="term"
                    maxlength="10"
                    placeholder="Term"
                    [group]="formGroup"
                    [required]="content.requireTerm"></nsc-form-field-input>
                </div>
                <div class="col-12 col-sm-6" *ngIf="formGroup.controls['year'].enabled && !show.futureProcessingOption">
                  <nsc-form-field-input
                    idx="delivery_year"
                    controlName="year"
                    hint="YYYY"
                    maxlength="4"
                    placeholder="Year"
                    [group]="formGroup"
                    [mask]="masks.year"
                    [required]="content.requireYear"></nsc-form-field-input>
                </div>
                <div class="col-12 col-sm-6" *ngIf="formGroup.controls['term'].enabled && show.futureProcessingOption">
                  <nsc-form-field-select
                    idx="delivery_term"
                    controlName="term"
                    placeholder="Term"
                    [group]="formGroup"
                    [required]="content.requireTerm"
                    [selectObjects]="values.fpOptionTerms">
                  </nsc-form-field-select>
                </div>
                <div class="col-12 col-sm-6" *ngIf="formGroup.controls['year'].enabled && show.futureProcessingOption">
                  <nsc-form-field-select
                    idx="delivery_year"
                    hint="YYYY"
                    controlName="year"
                    placeholder="Year"
                    [group]="formGroup"
                    [required]="content.requireYear"
                    [selectObjects]="values.fpOptionYears">
                  </nsc-form-field-select>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-8 col-lg-6" *ngIf="formGroup.controls['degreeTitle'].enabled">
              <nsc-form-field-input
                idx="delivery_degreeTitle"
                controlName="degreeTitle"
                maxlength="80"
                placeholder="Degree Title"
                [group]="formGroup"
                [required]="content.requireDegree"></nsc-form-field-input>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-8 col-lg-6" *ngIf="formGroup.controls['transcriptType'].enabled">
          <nsc-form-field-select
            idx="delivery_transcript_type"
            controlName="transcriptType"
            placeholder="What type of transcript do you want?"
            [group]="formGroup"
            [selectObjects]="values.transcriptTypes"></nsc-form-field-select>
        </div>

        <div class="col-12"></div>

        <div class="col-12 col-md-8 col-lg-6" *ngIf="formGroup.controls['transcriptPurpose'].enabled">
          <nsc-form-field-select
            idx="delivery_transcript_purpose"
            controlName="transcriptPurpose"
            placeholder="Why are you ordering your transcript?"
            [group]="formGroup"
            [selectObjects]="values.transcriptPurposes"></nsc-form-field-select>
        </div>
      </div>
    </nsc-card-content>
  </nsc-card>

  <nsc-spacer size="md"></nsc-spacer>

  <nsc-card>
    <nsc-card-title>Delivery Information</nsc-card-title>
    <nsc-card-content>
      <div class="row">
        <div class="col-12" *ngIf="formGroup.controls['processingMethod'].enabled">
          <div >
            Please indicate if you would like standard or rush processing. For your school’s definition of their processing and delivery option(s), please read the specific delivery text below.
          </div>
          <nsc-spacer size="sm"></nsc-spacer>
        </div>

        <div class="col-12 col-md-8 col-lg-6" *ngIf="formGroup.controls['processingMethod'].enabled">
          <nsc-form-field-select
            idx="delivery_processing_method"
            controlName="processingMethod"
            placeholder="How would you like this to be processed?"
            [group]="formGroup"
            [selectObjects]="values.processingMethods"></nsc-form-field-select>
        </div>

        <div class="col-12"></div>

        <div class="col-12 col-md-8 col-lg-6">
          <nsc-form-field-select
            idx="delivery_deliveryMethod"
            controlName="deliveryMethod"
            placeholder="How do you want your transcript sent?"
            [group]="formGroup"
            [selectObjects]="values.deliveryMethods"></nsc-form-field-select>
        </div>

        <div class="col-12"></div>

        <div class="col-12 col-md-8 col-lg-6" *ngIf="formGroup.controls['deliveryMethod'].value">
          <nsc-form-field-select
            idx="delivery_copies_count"
            controlName="howMany"
            placeholder="How many copies do you want?"
            [group]="formGroup"
            [selectObjects]="values.howMany"></nsc-form-field-select>
        </div>

        <div class="col-12"></div>

        <div class="col-12 col-md-8 col-lg-6" *ngIf="formGroup.controls['specialInstructions'].enabled">
          <mat-form-field appearance="outline" >
            <mat-label>Enter other required instructions only</mat-label>
            <textarea
              matInput
              matTextareaAutosize
              formControlName="specialInstructions"
              maxlength="255"></textarea>
              <mat-hint>(Optional)</mat-hint>
          </mat-form-field>
        </div>

        <div class="col-12" *ngIf="formGroup.controls['deliveryMethod'].value">
          <span >
            <strong>School's Terms and Conditions:</strong><br>
            {{ content.deliveryMethodHelpText }}
          </span>
        </div>

        <div class="col-12" *ngIf="formGroup.controls['deliveryMethod'].value">
          <nsc-spacer size="md"></nsc-spacer>
          <div class="row">
            <div class="col-lg-10 accept_terms" >
            <nsc-form-field-toggle
              idx="delivery_acceptTerms"
              controlName="acceptTerms"
              label="I have read and accept my school's terms and conditions for the delivery method of {{ convertDeliveryMethod.get(content.deliveryMethodDisplay) }}?"
              note="Acceptance to the Terms and Conditions is required."
              [group]="formGroup"></nsc-form-field-toggle>
            </div>
          </div>

            <div class="row" *ngIf="showIndexConsent">
              <div class="col-lg-10">
              <nsc-form-field-toggle
              idx="delivery_consentShareInfo"
              controlName="consentShareInfo"
              label="I authorize the Clearinghouse to share the information I have provided as part of this transcript order, including contact information, with the transcript recipient."
              [group]="formGroup"
              help="Contact information shared with the recipient includes full name, 
              address, and email address. The transcript recipient requests this data to significantly 
              reduce the time to intake your transcript."></nsc-form-field-toggle>
              </div>
          </div>
        </div>

      </div>
    </nsc-card-content>
  </nsc-card>

  <div *ngIf="content.allowUploads && show.docUploadSection">
    <nsc-spacer size="md"></nsc-spacer>

    <nsc-card>
      <nsc-card-title note="(optional)">Upload Attachment</nsc-card-title>
      <nsc-card-content>
        <div>Do you want to send additional documents with your transcript?</div>
        <nsc-form-field-files
          [url] = "values.attachmentUrl"
          [allowedFileTypes]="content.allowedFileTypes"
          [maxFileCount]="content.attachmentCount">
        </nsc-form-field-files>

        <nsc-spacer size="md" *ngIf="show.allowDocWaived"></nsc-spacer>
        <div class="col-lg-10" *ngIf="show.allowDocWaived">
          <nsc-form-field-toggle  idx="allowDocWaived_field" controlName="allowDocWaived"
            label="Do any of the files uploaded require action or review by your school? If 'No' is selected, your school may deliver these attachments as is without reviewing them."
            [group]="formGroup"></nsc-form-field-toggle>
        </div>
      </nsc-card-content>
    </nsc-card>
  </div> 

  <div *ngIf="values.fees && values.fees.totalFee > 0 || (values.fees && (isTNSchool || isSPDESchool || isFASTSchool) &&  values.fees.totalFee === 0)">
    <nsc-spacer size="md"></nsc-spacer>

    <nsc-recipient-delivery-method-fees (showTNFeeDetails)="showTNFeeDetailsPopUp()" (showPartnerNetworkInfo)="showPartnerNetworkInfoPopUp()"
      [fees]="values.fees" [isTNSchool]="isTNSchool" [isSPDESchool] ="isSPDESchool" [isFASTSchool]="isFASTSchool"></nsc-recipient-delivery-method-fees>
  </div>

  <nsc-spacer size="md"></nsc-spacer>

  <div class="nsc-form-actions">
    <button
      mat-raised-button
      id="recepientdeleivery_previous"
      class="nsc-form-actions__button  nsc-form-actions__button  button-big"
      color="primary"
      type="button"
      (click)="previous()"><mat-icon   aria-hidden="true">chevron_left</mat-icon> Previous</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      mat-stroked-button
      id="recepientdeleivery_cancel"
      class="nsc-form-actions__button button-big"
      color="primary"
      type="button"
      (click)="cancel()">Cancel Order</button>
    <nsc-spacer horizontal="true" size="md"></nsc-spacer>
    <button
      mat-raised-button
      id="recepientdeleivery_continue"
      class="nsc-form-actions__button  nsc-btn__right button-big"
      color="primary"
      type="button"
      [disabled]="isDisabled()"
      (click)="continue()">Continue <mat-icon aria-hidden="true" *ngIf="formGroup.valid && (formGroup.controls.acceptTerms.value === this.values.yes)" >chevron_right</mat-icon></button>
  </div>
</form>
 
